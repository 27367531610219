import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Customers from "../components/customers"
import FeatureColumn from "../components/feature-column"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import {
  faCode,
  faChartLine,
  faMedal,
  faCheck,
} from "@fortawesome/free-solid-svg-icons"

/*
BECOME A PARTNER
Enable your customers and portfolio with Celantur's trusted Anonymization Technology

CTA: Become a Partner


BOOST YOUR BUSINESS, ENABLE CUSTOMERS (was ist Wolfgangs hauptantrieb?)
Add Celantur's trusted anonymization service to your solution portfolio


Trusted by ...
*/

const BecomePartner = ({ data }) => (
  <Layout>
    <SEO title="Become a Partner - Celantur" description="..." />
    <section
      className="container-fluid pt-8 pt-md-12 pb-8 pb-md-12 overlay overlay-black overlay-80 bg-cover text-center"
      style={{
        background: "url(/images/earth-horizon.jpg) no-repeat",
        backgroundPositionY: "center",
        backgroundSize: "cover",
      }}
    >
      <div className="container">
        <h1 className="display-2 font-weight-bold text-white">
          Boost Your Business
          <br />
          Enable Customers
        </h1>
        <p className="lead font-weight-bold mb-6 mb-md-8 text-success">
          Add Celantur's trusted anonymization service to your solution
          portfolio
        </p>
        <Link
          to="/contact/"
          className="btn btn-success lift mr-1 event-contact"
        >
          Become a Partner
        </Link>
      </div>
    </section>

    <section className="py-8">
      <div className="container">
        <p
          className="text-center text-uppercase text-gray-400"
          style={{ letterSpacing: 1 }}
        >
          Industry Leaders trusting Celantur
        </p>
        <Customers />
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-md-10 col-md-8 text-center">
            <h2 className="font-weight-bold">
              Meet the increasing demand for anonymization technology
            </h2>
            <p className="font-size-lg mb-7 mb-md-9">
              Data protection laws, contract requirements and growing public
              interest are increasing the demand for data anonymization.
              Celantur provides a platform and technology to achieve data
              anonymization for images and videos at scale.
            </p>
          </div>
        </div>
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-md-6 text-center">
            <h1>Reselling</h1>

            <p className="font-size-lg text-gray-700 mb-6">
              Adding Celantur to your product portfolio enables your customers
              to meet growing demands when it comes to data protection.
            </p>
            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="d-flex">
                  <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "#42ba96" }}
                    />
                  </div>

                  <p className="text-success">Discount</p>
                </div>

                <div className="d-flex">
                  <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "#42ba96" }}
                    />
                  </div>

                  <p className="text-success mb-lg-0">Training</p>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "#42ba96" }}
                    />
                  </span>

                  <p className="text-success">Turnkey Solution</p>
                </div>

                <div className="d-flex">
                  <div className="badge badge-rounded-circle badge-success-soft mr-1 mr-4">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "#42ba96" }}
                    />
                  </div>

                  <p className="text-success mb-0">Partner Benefits</p>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-6 text-center">
            <h1>Integration</h1>

            <p className="font-size-lg text-gray-700 mb-6">
              Integrating Celantur's technology into your software or platform
              gives you a competetive advantage, by providing an essential
              feature to your customers.
            </p>

            <div className="row">
              <div className="col-12 col-lg-6">
                <div className="d-flex">
                  <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "#42ba96" }}
                    />
                  </div>

                  <p className="text-success">Cloud</p>
                </div>

                <div className="d-flex">
                  <div className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "#42ba96" }}
                    />
                  </div>

                  <p className="text-success mb-lg-0">Docker Container</p>
                </div>
              </div>
              <div className="col-12 col-lg-6">
                <div className="d-flex">
                  <span className="badge badge-rounded-circle badge-success-soft mt-1 mr-4">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "#42ba96" }}
                    />
                  </span>

                  <p className="text-success">On-premise</p>
                </div>

                <div className="d-flex">
                  <div className="badge badge-rounded-circle badge-success-soft mr-1 mr-4">
                    <FontAwesomeIcon
                      icon={faCheck}
                      style={{ color: "#42ba96" }}
                    />
                  </div>

                  <p className="text-success mb-0">API</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>

    <section className="py-8">
      <div className="container">
        <div className="row justify-content-between">
          <div className="col-12 col-md">
            <blockquote className="blockquote mb-8 mb-md-0">
              <div
                className="img-fluid mb-5 mb-md-6"
                style={{ maxWidth: "160px" }}
              >
              </div>

              <p className="lead text-gray-600 mb-5 mb-md-7">
                "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation."
              </p>

              <p className="font-size-sm font-weight-bold mb-0">
                Wolfgang Probst
              </p>
              <p className="font-size-sm text-muted mb-0">CEO of AllTerra DS</p>
            </blockquote>
          </div>
          <div className="col-1"></div>
          <div className="col-1 border-left"></div>
          <div className="col-12 col-md">
            <blockquote className="blockquote mb-0">
              <div
                className="img-fluid mb-5 mb-md-6 svg-shim"
                style={{ maxWidth: "120px" }}
              ></div>

              <p className="lead text-gray-600 mb-5 mb-md-7">
                “Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do
                eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut
                enim ad minim veniam, quis nostrud exercitation.”
              </p>

              <p className="font-size-sm font-weight-bold mb-0">TBD</p>
              <p className="font-size-sm text-muted mb-0">TBD</p>
            </blockquote>
          </div>
        </div>
      </div>
    </section>

    <section className="py-5">
      <div className="container">
        <div className="row">
          <FeatureColumn headline="Quality" icon={faMedal}>
            State-of-the-art technology ensures industry-grade anonymization.
          </FeatureColumn>
          <FeatureColumn headline="Scalable" icon={faChartLine}>
            Process large amounts of data with ease. Our machine learning models
            run in the cloud and scale with your needs.
          </FeatureColumn>
          <FeatureColumn headline="Easy to Integrate" icon={faCode}>
            ...
          </FeatureColumn>
        </div>
      </div>
    </section>
  </Layout>
)

export default BecomePartner
